import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/anthony/Projects/blog/src/components/blog-post-layout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Autumn quickly turned to winter and the slow wander from consistent training to the warm glow of the front room had taken firm hold.`}</MDXTag>
      <MDXTag name="p" components={components}>{`It is time to knock off the rust and get out and remind myself of the feeling of freedom that only being out in the hills can give.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`You come for the views`}</MDXTag>
      <MDXTag name="p" components={components}><figure className="gatsby-resp-image-figure" style={{}}>
    <span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "835px"
          }}>
      <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
  <picture>
        <source srcSet="/static/53c6a232352ea178a1a96f84ab49f9dd/1e598/claggy-path.webp 209w,
/static/53c6a232352ea178a1a96f84ab49f9dd/cd572/claggy-path.webp 418w,
/static/53c6a232352ea178a1a96f84ab49f9dd/2321d/claggy-path.webp 835w,
/static/53c6a232352ea178a1a96f84ab49f9dd/cd2f7/claggy-path.webp 1253w,
/static/53c6a232352ea178a1a96f84ab49f9dd/5afe4/claggy-path.webp 1670w,
/static/53c6a232352ea178a1a96f84ab49f9dd/26eaf/claggy-path.webp 3648w" sizes="(max-width: 835px) 100vw, 835px" type="image/webp" />
        <source srcSet="/static/53c6a232352ea178a1a96f84ab49f9dd/46435/claggy-path.jpg 209w,
/static/53c6a232352ea178a1a96f84ab49f9dd/a2ddf/claggy-path.jpg 418w,
/static/53c6a232352ea178a1a96f84ab49f9dd/cba2b/claggy-path.jpg 835w,
/static/53c6a232352ea178a1a96f84ab49f9dd/5c835/claggy-path.jpg 1253w,
/static/53c6a232352ea178a1a96f84ab49f9dd/29c69/claggy-path.jpg 1670w,
/static/53c6a232352ea178a1a96f84ab49f9dd/d116c/claggy-path.jpg 3648w" sizes="(max-width: 835px) 100vw, 835px" type="image/jpeg" />
        <img className="gatsby-resp-image-image" src="/static/53c6a232352ea178a1a96f84ab49f9dd/cba2b/claggy-path.jpg" alt="You can&#39;t guarantee a view" title="You can&#39;t guarantee a view" loading="lazy" style={{
                "width": "100%",
                "height": "100%",
                "margin": "0px",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0px",
                "left": "0px"
              }} />
      </picture>
    </span>
    <figcaption className="gatsby-resp-image-figcaption">You can&#39;t guarantee a view</figcaption>
  </figure></MDXTag>
      <MDXTag name="p" components={components}>{`You come for the views but they cannot be guaranteed, but the atmosphere of a claggy day is second to none. At one with yoru surroundings, in the moment relying on map and compass.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Pushing up hills until either the legs or lungs give in. ALways trying despratly for it not to be the mind that quits first.`}</MDXTag>
      <MDXTag name="p" components={components}><figure className="gatsby-resp-image-figure" style={{}}>
    <span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "835px"
          }}>
      <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
  <picture>
        <source srcSet="/static/77e271ea028478a0384c71b7e92aa4ac/1e598/cairn-in-sun.webp 209w,
/static/77e271ea028478a0384c71b7e92aa4ac/cd572/cairn-in-sun.webp 418w,
/static/77e271ea028478a0384c71b7e92aa4ac/2321d/cairn-in-sun.webp 835w,
/static/77e271ea028478a0384c71b7e92aa4ac/cd2f7/cairn-in-sun.webp 1253w,
/static/77e271ea028478a0384c71b7e92aa4ac/5afe4/cairn-in-sun.webp 1670w,
/static/77e271ea028478a0384c71b7e92aa4ac/26eaf/cairn-in-sun.webp 3648w" sizes="(max-width: 835px) 100vw, 835px" type="image/webp" />
        <source srcSet="/static/77e271ea028478a0384c71b7e92aa4ac/46435/cairn-in-sun.jpg 209w,
/static/77e271ea028478a0384c71b7e92aa4ac/a2ddf/cairn-in-sun.jpg 418w,
/static/77e271ea028478a0384c71b7e92aa4ac/cba2b/cairn-in-sun.jpg 835w,
/static/77e271ea028478a0384c71b7e92aa4ac/5c835/cairn-in-sun.jpg 1253w,
/static/77e271ea028478a0384c71b7e92aa4ac/29c69/cairn-in-sun.jpg 1670w,
/static/77e271ea028478a0384c71b7e92aa4ac/d116c/cairn-in-sun.jpg 3648w" sizes="(max-width: 835px) 100vw, 835px" type="image/jpeg" />
        <img className="gatsby-resp-image-image" src="/static/77e271ea028478a0384c71b7e92aa4ac/cba2b/cairn-in-sun.jpg" alt="Carn heading across cut gate" title="Carn heading across cut gate" loading="lazy" style={{
                "width": "100%",
                "height": "100%",
                "margin": "0px",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0px",
                "left": "0px"
              }} />
      </picture>
    </span>
    <figcaption className="gatsby-resp-image-figcaption">Carn heading across cut gate</figcaption>
  </figure></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    